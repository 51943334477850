// import function to register swiper custom elements
import { register } from 'swiper/element/bundle';
import { Autoplay } from 'swiper/modules';
import React from "react";
import Tabs from "./components/Tabs";
import { Gallery } from "react-grid-gallery";
import './App.css';
import tigerlogo from "./assets/logos/tigerlogoMAIN.svg";
import ppic1 from "./assets/images/ballooons.jpg";
import ppic2 from "./assets/images/doggo.jpg";
import ppic3 from "./assets/images/piggies.jpg";
import ppic4 from "./assets/images/tigerkid.jpg";
import ppic5 from "./assets/images/bluecat.jpg";
import ppic6 from "./assets/images/IMG_1216.jpg";
import biopic from "./assets/images/SabinaHeadshot.png";
register();

  const images = [
   {
      src: ppic1,
      width: 2290,
      height: 2241,
   },
   {
      src: ppic2,
      width: 1934,
      height: 2577,
   },
   {
      src: ppic3,
      width: 4376,
      height: 3283,
   },
   {
      src: ppic4,
      width: 2559,
      height: 1574,
   },
   {
      src: ppic5,
      width: 2224,
      height: 1688,
   },
   {
      src: ppic6,
      width: 4032,
      height: 3024,
   },
];

export default function App() {
  return (
	<main>
	<img src={tigerlogo} className='App-logo' alt=''/> 
	<div>
    	<Tabs>
        <div label="Home">
			<h3 className='site-txt-home'>
				Sabina is a lifelong artist and printmaker who specializes in picture book illustration.
				She is based in Houston, TX, and loves coffee, cats, and color! 
			</h3>	
			<swiper-container
			pagination="true" 
			navigation="true"
			style={{
				"--swiper-pagination-color": "fff",
				"--swiper-navigation-color": "faf3dd"
			}}>
				<swiper-slide>
					<img src={ppic6}/>
				</swiper-slide>
				<swiper-slide>
					<img src={ppic2}/>
				</swiper-slide>
				<swiper-slide>
					<img src={ppic3}/>
				</swiper-slide>
				<swiper-slide>
					<img src={ppic4}/>
				</swiper-slide>
					<swiper-slide>
				<img src={ppic5}/>
				</swiper-slide>
				<swiper-slide>
					<img src={ppic1}/>
				</swiper-slide>
			</swiper-container> 
        </div>
		
		<div label="About">

			<img src={biopic} className='bio'/>
			
			<h3 className='site-txt-about'>	
				Sabina is a lifelong artist and printmaker who specializes in picture book illustration. 
				She is based in Houston, TX, and loves coffee, cats, and color! 
				She is a member of the Society of Children's Book Writers and Illustrators and the Graphic
				Artists Guild. She seeks to someday work on middle grade graphic novels, and push the boundaries 
				of how much coffee the human body is equipped to handle.
				Her illustrator inspirations include Bill Watterson, Jan Brett, Corey R. Tabor, and Luke Pearson.
			</h3>
		</div>

		
		<div label="Portfolio">
			<h3 className='site-txt-portfolio'>
				The majority of my work is sketched traditionally, then scanned, inked, and colored in Procreate for the iPad.
			</h3>
			<Gallery 
				images={images}
				enableImageSelection="false"
				rowHeight={600}
				margin={20}
			/>
		</div>
		<div label="Contact">
			<h2 className='site-txt-contact-email'>
				<a href="mailto:sabina@rosegrey.org">sabina@rosegrey.org</a>
			</h2>	
			<h3 className='site-txt-contact'>
				I'm currently available for freelance illustration and contract opportunities (As of May 2024). 
				Feel free to shoot me an email to get a conversation going!       
			</h3>	
			
		</div>
		
		<div label="Shop">
			<h2 className='site-txt-shop'>Coming Soon!</h2>	
		</div>
        
		</Tabs>
    </div> 
    </main>
  )
}